@import "../../../styles.scss";
.TextAreaInput {
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 2px;
        @include outlined-text;
    }
    textarea {
        height: 75px;
        padding: 5px 10px !important;
        @include standard-shadow;
        @include standard-input;
    }
    textarea:focus {
        @include standard-focus;
    }
}
