@mixin standard-shadow {
    border: solid black 3px;
    box-shadow: -2px 4px 0px 0px #000;
    -webkit-box-shadow: -2px 4px 0px 0px #000;
    -moz-box-shadow: -2px 4px 0px 0px #000;
}

@mixin standard-input {
    border-radius: 10px;
    box-sizing: border-box;
    background-color: white;
    @include input-text();
    padding: 0px 10px;
}

@mixin standard-focus {
    transform: translateX(-2px) translateY(4px);
    box-shadow: none;
    outline: none !important;
}

@mixin white-text {
    color: white;
    font-family: Bebas Neue;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.8px;
}

@mixin outlined-text {
    @include white-text();
    text-shadow: 1.2px 1.2px 0 #000, -1.2px -1.2px 0 #000, 1.2px -1.2px 0 #000,
        -1.2px 1.2px 0 #000, 0px 1.2px 0 #000, 1.2px 0px 0 #000,
        0px -1.2px 0 #000, -1.2px 0px 0 #000;
}

@mixin input-text {
    color: rgb(0, 0, 0);
    font-family: Bebas Neue;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
}
