$bezier: 2s cubic-bezier(0.8, -0.05, 0.34, 0.97) 1;

.Hero {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .Title {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        max-width: 500px;
        height: 125px;
    }
    .Hero-Content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-top: -20px;
        padding-bottom: 20px;
        min-height: 450px;
        .Flower {
            width: 115%;
            margin-top: 10px;
            max-width: 450px;
            animation: flower-spin infinite 120s linear;
        }
        .card-group {
            position: absolute;
            display: grid;
            place-items: center;
            width: 100%;
            height: 1000px;
            overflow-x: hidden;
            z-index: 12;
            .item {
                position: absolute;
                -webkit-transition: all 1s cubic-bezier(0.12, 0.7, 0.48, 0.98);
                transition: all 1s cubic-bezier(0.12, 0.7, 0.48, 0.98);
                background-position: center;
                background-size: cover;
                animation-fill-mode: forwards !important;
            }
            .card {
                aspect-ratio: 5/7;
                width: 160px;
                border-radius: 10px;
                border: solid 2px black;
            }
            .heart {
                aspect-ratio: 1/1;
                width: 35px;
                background-image: url("../../../assets/BigHeart.svg");
            }
            .heart-small {
                aspect-ratio: 1/1;
                width: 23px;
                background-image: url("../../../assets/MedHeart.svg");
            }
            .card:nth-child(1) {
                background-image: url("../../../assets/1.jpeg");
                z-index: 11;
                animation: transform1 $bezier;
            }
            .card:nth-child(2) {
                background-image: url("../../../assets/2.jpeg");
                z-index: 10;
                animation: transform2 $bezier;
            }
            .card:nth-child(3) {
                background-image: url("../../../assets/3.jpeg");
                z-index: 9;
                animation: transform3 $bezier;
            }
            .card:nth-child(4) {
                background-image: url("../../../assets/4.jpeg");
                z-index: 8;
                animation: transform4 $bezier;
            }
            .heart:nth-child(5) {
                z-index: 7;
                animation: transform5 $bezier;
            }
            .heart:nth-child(6) {
                z-index: 6;
                animation: transform6 $bezier;
            }
            .heart-small:nth-child(7) {
                z-index: 5;
                animation: transform7 $bezier;
            }
            .heart:nth-child(8) {
                z-index: 4;
                animation: transform8 $bezier;
            }
            .heart-small:nth-child(9) {
                z-index: 3;
                animation: transform9 $bezier;
            }
            .heart:nth-child(10) {
                z-index: 2;
                animation: transform10 $bezier;
            }
            .heart-small:nth-child(11) {
                z-index: 10;
                animation: transform11 $bezier;
            }
        }
    }
    Button {
        position: relative;
        z-index: 13;
        margin: -5px 40px 70px 40px;
    }
    .squigle {
        width: 105%;
        max-width: 450px;
    }
}

@keyframes flower-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes transform1 {
    0% {
        transform: translateX(15px) translateY(10px) rotate(9deg);
    }
    50% {
        transform: translateX(15px) translateY(10px) rotate(9deg);
    }
    100% {
        transform: translateX(150px) translateY(30px) rotate(24deg);
    }
}

@keyframes transform2 {
    0% {
        transform: translateX(5px) translateY(10px) rotate(3deg);
    }
    50% {
        transform: translateX(5px) translateY(10px) rotate(3deg);
    }
    100% {
        transform: translateX(50px) translateY(0px) rotate(8deg);
    }
}

@keyframes transform3 {
    0% {
        transform: translateX(-5px) translateY(10px) rotate(-3deg);
    }
    50% {
        transform: translateX(-5px) translateY(10px) rotate(-3deg);
    }
    100% {
        transform: translateX(-50px) translateY(0px) rotate(-8deg);
    }
}

@keyframes transform4 {
    0% {
        transform: translateX(-15px) translateY(10px) rotate(-9deg);
    }
    50% {
        transform: translateX(-15px) translateY(10px) rotate(-9deg);
    }
    100% {
        transform: translateX(-150px) translateY(30px) rotate(-24deg);
    }
}

@keyframes transform5 {
    0% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    50% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    100% {
        transform: translateX(-130px) translateY(-180px) rotate(15deg);
    }
}

@keyframes transform6 {
    0% {
        transform: translateX(0px) translateY(10px) rotate(-360deg);
    }
    50% {
        transform: translateX(0px) translateY(10px) rotate(-360deg);
    }
    100% {
        transform: translateX(150px) translateY(-170px) rotate(65deg);
    }
}

@keyframes transform7 {
    0% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    50% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    100% {
        transform: translateX(120px) translateY(-180px) rotate(35deg);
    }
}

@keyframes transform8 {
    0% {
        transform: translateX(0px) translateY(10px) rotate(-360deg);
    }
    50% {
        transform: translateX(0px) translateY(10px) rotate(-360deg);
    }
    100% {
        transform: translateX(-130px) translateY(200px) rotate(65deg);
    }
}

@keyframes transform9 {
    0% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    50% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    100% {
        transform: translateX(-160px) translateY(190px) rotate(35deg);
    }
}

@keyframes transform10 {
    0% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    50% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
    }
    100% {
        transform: translateX(130px) translateY(180px) rotate(25deg);
    }
}

@keyframes transform11 {
    0% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
        width: initial;
    }
    50% {
        transform: translateX(0px) translateY(10px) rotate(360deg);
        width: initial;
    }
    100% {
        transform: translateX(65px) translateY(-100px) rotate(60deg);
        width: 15px;
    }
}
