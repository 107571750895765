@import "../styles.scss";
.Modal {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal-content {
    background-color: #ff7777;
    margin: auto;
    padding: 20px;
    border: 3px solid #000000;
    width: 80%;
    max-width: 500px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
        font-size: 25px !important;
        line-height: 28px !important;
        text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
            -2px 2px 0 #000, 0px 2px 0 #000, 2px 0px 0 #000, 0px -2px 0 #000,
            -2px 0px 0 #000;
        @include white-text;
    }
}
