@import "../styles.scss";
.button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 5px 20px;
    border-radius: 20px;
    width: fit-content;
    min-width: 230px;
    @include standard-shadow;
    @include outlined-text;
    justify-content: center;
    align-items: center;
    align-self: center;
    img {
        height: 22px;
        width: fit-content;
    }
    &.primary {
        background-color: #ffae03;
    }
    &.secondary {
        background-color: #03c0ff;
    }
}
.button:active {
    @include standard-focus;
}
