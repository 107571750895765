@import "../../../styles.scss";
.SelectInput {
    display: flex;
    flex-direction: column;
    select {
        height: 40px;
        @include standard-input;
        @include standard-shadow;
    }
    select:focus {
        @include standard-focus;
    }
    label {
        margin-bottom: 2px;
        @include outlined-text;
    }
}
