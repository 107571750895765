@import "../../../styles.scss";
fieldset {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: -10px;
    .RadioInput {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        input {
            appearance: none;
            @include standard-shadow;
            @include standard-input;
            height: 30px;
            width: 100%;
        }
        input:checked {
            @include standard-focus;
            background-color: #ffae03;
        }
        label {
            @include outlined-text;
            position: relative;
        }
    }
    legend {
        @include outlined-text;
    }
}
