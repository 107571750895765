@import "../../components/styles.scss";
.ApprovedPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    justify-content: space-between;
    padding: 8vh 0 10vh 0;
    box-sizing: border-box;
    gap: 30px;
    overflow: hidden;
    .ApprovedImage {
        max-width: 390px;
        width: 100%;
    }
    .Picture {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 40px;
        overflow: hidden;
        position: relative;
        .Congrats {
            position: absolute;
            max-width: 300px;
            width: 100%;
            animation: spin infinite 120s linear;
        }
        .Me {
            aspect-ratio: 1/1;
            max-width: 200px;
            width: 100%;
            border-radius: 50%;
            background-color: aqua;
            background-image: url("../../../assets/1.jpeg");
            background-position: 45% 20%;
            background-size: cover;
            border: solid black 3px;
        }
    }
    .Socials {
        @include outlined-text;
        text-align: center;
        p {
            margin: 3px;
        }
    }
    .ButtonGroup {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
