@import "../styles.scss";
.ApplicationForm {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 60px 20px;
    gap: 20px;
    align-items: center;
    max-width: 400px;
    margin: auto;
    .formImage {
        width: 100%;
        max-width: 230px;
    }
    p {
        @include white-text;
        text-align: center;
        letter-spacing: 0.5px;
        line-height: 19px;
        margin: 0px;
    }
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 25px;
        .error-message {
            margin-top: 8px;
            @include white-text;
            color: rgb(181, 0, 0);
            font-size: 20px;
        }
        p {
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
            font-family: Arial;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: left;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        .buttonGroup {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}
