@import "../../styles.scss";

.imageInput {
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
    .addPhoto {
        width: 100%;
        border-radius: 10px;
        height: 40px;
        border: 3px black solid;
        background-color: #ffae03;
        @include standard-shadow;
        span {
            @include outlined-text;
        }
    }
    .addPhoto:active {
        @include standard-focus;
    }
    label {
        @include outlined-text;
        margin-bottom: -17px;
    }

    video,
    .userImage {
        background-color: black;
        width: 100%;
        height: auto;
        border: 3px black solid;
        box-sizing: border-box;
        border-radius: 10px;
        box-shadow: 2px 4px 0px 0px #000;
        z-index: 1;
    }

    video {
        aspect-ratio: 3/4;
    }

    .capture {
        width: 50px;
        height: 50px;
        border: 3px black solid;
        @include standard-shadow;
        background-color: #ffae03;
        border-radius: 50px;
        margin-top: -90px;
        align-self: center;
        z-index: 2;
        margin-bottom: 40px;
        @include white-text;
        letter-spacing: 0px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
    }
    .capture:active {
        @include standard-focus;
    }
}
