@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap");
.App {
    background-color: #ff7777;
    display: flex;
    justify-content: center;
    .Wrapper {
        max-width: 800px;
        width: 100%;
    }
}
